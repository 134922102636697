.logocolor {
  color: blue;
  transition: 0.5s;
}

.droplogo:hover .logocolor {
  color: #f86f03;
}

@keyframes flashingBackground {
  0% {
    background-color: #f86f03;
    box-shadow: 0 0 5px #f86f03;
  }
  50% {
    background-color: #d96f1e;
    box-shadow: 0 0 40px #d96f1e;
  }
  100% {
    background-color: #f86f03;
    box-shadow: 0 0 5px #f86f03;
  }
}

.flashingButton {
  animation: flashingBackground 2s infinite;
}
